/** @format */

import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { useLoginState } from "@/hooks";

interface Props {
	children: ReactNode;
	needRoles?: string[];
	path?: string;
	permissList?: string[];
}

//  做鉴权路由

export default function AuthRoute(props: Props) {
	const loginState = useLoginState();

	// const dispatch = useAppDispatch() // redux使用方法  跟你的vuex类似

	//  1.这是个受保护路由， 根据角色role来判断是否有权限进入
	// 2. 外贸这个项目目前更加着重的是按钮的权限， 后续可以写一个受保护的按钮组件，  根据登录用户按钮权限key来指定是否渲染。
	return <>{loginState ? props.children : <Navigate to="/login" />}</>;
}
