/** @format */

import axios, {
	AxiosResponse,
	AxiosError,
	AxiosRequestConfig,
	InternalAxiosRequestConfig,
	HttpStatusCode,
} from "axios";
import { BASEURL } from "@/config";
import { get } from "lodash";
import { message } from "antd";
interface AxiosReturn<T = any> {
	code: HttpStatusCode;
	data: T;
	msg: string;
}


// interface AxiosError {

// }

// const locale = localStorage.getItem("locale") || "zh"; // 'zh'| 'en'   做国际化的话， 需要自己把语言存在本地， 默认中文
const instance = axios.create();
instance.defaults.baseURL = BASEURL;
//默认超时时间
instance.defaults.timeout = 600000;
instance.defaults.validateStatus = function (status) {
	return status >= 200 && status <= 500;
};

instance.interceptors.request.use(
	(config: InternalAxiosRequestConfig<any>) => {
		//@ts-ignore
		config.headers = {
			...config.headers,
			["tenant-id"]: "145",
		};

		const token =
			window.localStorage.getItem("accessToken") ||
			window.sessionStorage.getItem("accessToken");
		if (token && token !== "undefined") {
			config.headers.Authorization = "Bearer " + token;
		}

		//  删除undefined字段
		const { data, params } = config;

		if (data) {
			for (const key in data) {
				if (!data[key] === undefined) delete data[key];
			}
		}
		if (params) {
			for (const key in params) {
				if (params[key] === undefined) delete params[key];
			}
		}

		return config;
	},
	(error: any) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(res: AxiosResponse<AxiosReturn>) => {
		const status = res.data.code || res.status;
		if (status === 401) {
			localStorage.clear()
			sessionStorage.clear()
			window.location.pathname = "/login";
			// localStorage.clear()
			// sessionStorage.clear()
			// store.dispatch(setIsLogined(false))
		
		} else if (status < 200 || status >= 400) {
			return Promise.reject(res);
		}
		return res;
	},
	(error: AxiosResponse<AxiosReturn, any> | AxiosError) => {
		const msg = get(error, "data.msg") || get(error, "message") || get(error, "data.error");
		if (msg) {
			message.destroy();
			message.error(msg);
		}

		return Promise.reject({ msg, isFail: true });
	}
);

const request = {
	get: function (url: string, params?: any, config: AxiosRequestConfig = {}): Promise<any> {
		return instance
			.get(url, {
				params,
				...config,
			})
			.then((response: AxiosResponse<AxiosReturn, any>) => {
				return response.data.data ? response.data.data : response.data;
			})
			.catch((error) => {
				const msg =
					get(error, "data.msg") || get(error, "message") || get(error, "data.error");

				if (msg) {
					message.destroy();
					message.error(msg);
				}
				return { msg, isFail: true };
			});
	},
	post: function (url: string, params: any, config?: AxiosRequestConfig): Promise<any> {
		return instance
			.post(url, params, config)
			.then((response: AxiosResponse<AxiosReturn<any>, any>) => {
				return response.data.data ? response.data.data : response.data;
			})
			.catch((error) => {
				const msg =
					get(error, "data.msg") || get(error, "message") || get(error, "data.error");

				if (msg) {
					message.destroy();
					message.error(msg);
				}
				return { msg, isFail: true };
			});
	},
	put: function (url: string, params: any, config?: AxiosRequestConfig): Promise<any> {
		return instance
			.put(url, params, config)
			.then((response: AxiosResponse<AxiosReturn, any>) => {
				return response.data.data ? response.data.data : response.data;
			})
			.catch((error) => {
				const msg =
					get(error, "data.msg") || get(error, "message") || get(error, "data.error");
				message.destroy();
				if (msg) {
					message.destroy();
					message.error(msg);
				}
				return { msg, isFail: true };
			});
	},
	delete: function (url: string, data: any, config?: AxiosRequestConfig): Promise<any> {
		return instance
			.delete(url, {
				...config,
				data,
			})
			.then((response: AxiosResponse<AxiosReturn<any>, any>) => {
				return response.data.data ? response.data.data : response.data;
			})
			.catch((error) => {
				const msg =
					get(error, "data.msg") || get(error, "message") || get(error, "data.error");

				if (msg) {
					message.destroy();
					message.error(msg);
				}
				return { msg, isFail: true };
			});
	},
};

export interface CommonRes {
	msg?: string;
	isFail?: boolean;
}
export default request;
