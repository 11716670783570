/** @format */

import { User } from "@/types";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store";
export interface UserState {
	user: User;

	isLogined: boolean;
}

const initialState: UserState = {
	user: {
		avatar: "",
		id: -1,
		loginIp: "",
		mobile: "",
		nickname: "",
		questCt: -1,
		sex: 0,
		status: 0,
		username: "",
	},

	isLogined: false,
};

export const userSlice = createSlice({
	name: "user", // 命名空间
	initialState, // login.storeState
	reducers: {
		setUser: (state: UserState, action) => {
			// 这里只能做同步操作，否则是非法的！。
			// reducer function(storeState,actionfunction(PayloadAction<typeof value>))
			state.user = action.payload;
		},
	},
});

export const { setUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;

export default userSlice.reducer;
