import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import RootComp from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from '@/store'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/locale/zh_CN';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <RootComp />
    </Provider>
  </ConfigProvider>
  // </React.StrictMode>
);


reportWebVitals();
