
import Loading from "./components/Loading";
import Root from "@/router/root";
import { Suspense } from "react";
import { ConfigProvider } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from 'antd';




const RootComp: React.FC = () => {

  return (
    <Suspense fallback={<Loading />}>
      <ConfigProvider theme={{
        token: {
          colorPrimary: '#AACD06',
        },
      }} >
        <Router>
          <App>
            <Root />
          </App>
        </Router>
      </ConfigProvider>
    </Suspense>
  );
}
export default RootComp