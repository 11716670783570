/** @format */

import { User } from "@/types";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store";
export interface LoginState {
	user: User;
	
	isLogined: boolean;
}

const initialState: LoginState = {
	user: {
		avatar: "",
		id: "" as any,
		loginIp: "",
		mobile: "",
		nickname: "",
		questCt: 0,
		sex: 0,
		status: 0,
		username: "",
	},
	
	isLogined: false,
};

export const loginSlice = createSlice({
	name: "login", // 命名空间
	initialState, // login.storeState
	reducers: {
		setUser: (state: LoginState, action) => {
			// 这里只能做同步操作，否则是非法的！。
			// reducer function(storeState,actionfunction(PayloadAction<typeof value>))
			state.user = action.payload;
		},
		
		setIsLogined: (state: LoginState, action) => {
			state.isLogined = action.payload;
		},
	},
});

export const { setUser, setIsLogined } = loginSlice.actions;

export const selectUser = (state: RootState) => state.login.user;
export const selectIsLogined = (state: RootState) => state.login.isLogined;

export default loginSlice.reducer;
