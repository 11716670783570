/** @format */

import loginSliceReducer from "@/store/reducers/login.slice";
import companySliceReducer from "@/store/reducers/company.slice";
import userSliceReducer from "@/store/reducers/user.slice";
import { LoginAPI } from "@/api/login";
const reducer = {
	login: loginSliceReducer,
	user: userSliceReducer,
	company: companySliceReducer,
	[LoginAPI.reducerPath]: LoginAPI.reducer,
};
export default reducer;
