import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectUser, selectIsLogined } from '@/store/reducers/login.slice'

export const useUser = () => {
    const user = useSelector(selectUser)

    return useMemo(() => (user), [user])
}

export const useLoginState = () => {
    const isLogined = useSelector(selectIsLogined)
    return useMemo(() => (isLogined), [isLogined])
}

