/** @format */

import { useState, useLayoutEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AuthRoute from "@/router/AuthRoute";
import { routers } from "@/router/routers";
import { AuthRouteObject } from "@/types";
import { setIsLogined, setUser } from "@/store/reducers/login.slice";
import { useAppDispatch } from "@/store/typedHooks";
import { getUserAPI } from "@/api/userCenter";
import { Spin } from "antd";
interface Props {}

//  刷新token
const formatRouter = (routers: AuthRouteObject[]) => {
	return routers.map((route) => {
		const { path, auth, roles, children, permissList } = route;
		if (auth || roles) {
			// 权限路由
			route.element = (
				<AuthRoute path={path} needRoles={roles} permissList={permissList}>
					{route.element}
				</AuthRoute>
			);
		}
		if (children && children.length > 0) {
			route.children = formatRouter(children);
		}

		return route;
	});
};

let _routers = formatRouter(routers);

const renderRoutes = (routes: AuthRouteObject[]) => {
	return routes.map((r, _index) => {
		let { children, path, element, index } = r;

		return (
			<Route key={path + "_" + _index} index={index} path={path} element={element}>
				{children && children.length > 0 ? renderRoutes(children) : null}
			</Route>
		);
	});
};
// 创建路由
const Root = (props: Props) => {
	const [hasInit, setInit] = useState(false);
	const dispatch = useAppDispatch();

	//  useEffect useLayoutEffect执行2次问题
	//  1.这是 React18 才新增的特性。
	// 2.仅在开发模式("development")下，且使用了严格模式("Strict Mode")下会触发。
	//   生产环境("production")模式下和原来一样，仅执行一次。
	// 3.之所以执行两次，是为了模拟立即卸载组件和重新挂载组件。
	//   为了帮助开发者提前发现重复挂载造成的 Bug 的代码。
	//   同时，也是为了以后 React的新功能做铺垫。
	//   未来会给 React 增加一个特性，允许 React 在保留状态的同时，能够做到仅仅对UI部分的添加和删除。
	//   让开发者能够提前习惯和适应，做到组件的卸载和重新挂载之后， 重复执行 useEffect的时候不会影响应用正常运行。

	useLayoutEffect(() => {
		const localToken = window.localStorage.getItem("accessToken");

		const sessionToken = window.sessionStorage.getItem("accessToken");

		let isLogined =
			(localToken && localToken !== "undefined") ||
			(sessionToken && sessionToken !== "undefined");
		dispatch(setIsLogined(isLogined));
		if (isLogined) {
			getUserAPI().then(({ isFail, ...user }) => {
				if (!isFail) {
					dispatch(setUser(user));
				} else {
					dispatch(setIsLogined(false));
				}
			});
		}
		setInit(true);
	}, []);

	return hasInit ? (
		<Routes>{renderRoutes(_routers)}</Routes>
	) : (
		<div style={{ height: "100%", width: "100%" }} className="flex_center">
			<Spin className="flex_center" style={{ height: "100%", display: "flex" }}></Spin>
		</div>
	);
};

export default Root;
