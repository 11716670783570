/** @format */

import { lazy } from "react";
import { AuthRouteObject } from "@/types";

import { Navigate } from "react-router-dom";
const Home = lazy(() => import("../pages/home"));
const Layout = lazy(() => import("../pages/layout"));
const Login = lazy(() => import("../pages/login"));
const NotFound = lazy(() => import("../pages/404"));
const NotAuth = lazy(() => import("../pages/403"));
const UserCenter = lazy(() => import("../pages/userCenter"));
const FindPassword = lazy(() => import("../pages/findPassword"));
const CreateComp = lazy(() => import("../pages/createComp"));
export const routers: AuthRouteObject[] = [
	{
		path: "/",
		element: <Navigate to="/chat"></Navigate>,
	},
	{
		path: "/",
		element: <Home />,
		auth: true,
		children: [
			{
				path: "/chat",
				element: <Layout />,
				auth: true,
			},
			{
				path: "/user",
				element: <UserCenter />,
				auth: true,
			},
		],
	},

	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/createComp",
		element: <CreateComp />,
		auth: true,
	},
	{
		path: "/changePassword",
		element: <FindPassword></FindPassword>,
	},

	{
		path: "/403",
		element: <NotAuth />,
	},
	{ path: "*", element: <NotFound /> },
];
