/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store";
import { company } from "@/types";
export interface CompanyState {
	companyList: company[];
	chooseCompId?: string;
}

const initialState: CompanyState = {
	companyList: [],
	chooseCompId: undefined,
};

export const companySlice = createSlice({
	name: "company", // 命名空间
	initialState, // company.storeState
	reducers: {
		setcompanyList: (state: CompanyState, action) => {
			state.companyList = action.payload;
		},
		setChooseCompId: (state: CompanyState, action) => {
			state.chooseCompId = action.payload;
		},
	},
});

export const { setcompanyList, setChooseCompId } = companySlice.actions;

export const selectcompanyState = (state: RootState) => state.company.companyList;
export const selectChooseCompId = (state: RootState) => state.company.chooseCompId;
export default companySlice.reducer;
