/** @format */

import request, { CommonRes } from "./index";
import { User } from "@/types";
export const uploadAvatarAPI = (params: FormData): Promise<string> => {
	return request.post("/member/user/update-avatar", params);
};

export const getUserAPI = (): Promise<User & CommonRes> => {
	return request.get("/member/user/get");
};

export const updateUserAPI = (params: any): Promise<boolean | CommonRes> => {
	return request.put("/member/user/update-user", params);
};

export const updateMobile = (params: { code: string; mobile: string; password: string }): Promise<boolean | CommonRes> => {
	return request.post("/member/user/update-mobile-by-password", params);
};
