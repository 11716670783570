import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import reducer from './reducers'
import { LoginAPI } from '@/api/login'
/**
 * creates a Redux store, and also automatically
 * configure the Redux DevTools extension so
 * that you can inspect the store while developing.
 **/
const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(LoginAPI.middleware),
});



export type RootState = ReturnType<typeof store.getState>;


export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export default store;