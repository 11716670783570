
//  发送request使用redux-toolkit 内置的request方式 ，  没必要用aixos   淘汰了。
import { createApi, fetchBaseQuery, } from '@reduxjs/toolkit/query/react'
import { RegisteRes } from './registe'
import request, { CommonRes } from './index'


/**
 * AppAuthSmsLoginReqVO，用户 APP - 手机 + 验证码登录 Request VO,如果登录并绑定社交用户，需要传递 social 开头的参数
 */
export interface LoginByUserNameParams {
    mobile: string
    password: string
}

export interface LoginByUserNameRes extends RegisteRes {

}
export interface SmsCodeParams {
    mobile: string // 手机号
    scene: 1 | 2 | 3 | 4 // 枚举值  不知道其他类型
}
export interface LoginByCodeParams {
    mobile: string
    code: string
}

export interface refreshTokenParams {
    refreshToken: string
}





export const loginByCodeAPI = (params: LoginByCodeParams): Promise<LoginByUserNameRes> => {
    return request.post('/member/auth/sms-login', params)
}

export const loginByMobileAPI = (params: LoginByUserNameParams): Promise<LoginByUserNameRes> => {
    return request.post('/member/auth/login', params)
}
export const refreshTokenAPI = (refreshToken: string): Promise<LoginByUserNameRes> => {
    return request.post(`/member/auth/refresh-token?refreshToken=${refreshToken}`, {})
}
export const getCodeAPI = (params: SmsCodeParams): Promise<string | CommonRes> => {
    return request.post("/member/auth/send-sms-code", params)
}

export const loginOutAPI = () => {
    return request.post('/member/auth/logout', {})
}

export const LoginAPI = createApi({
    reducerPath: 'LoginAPI', // 命名空间
    baseQuery: fetchBaseQuery({
        baseUrl: '/app-api',
        prepareHeaders: (headers,) => {
            headers.set('tenant-id', `1`)
            return headers
        },
    }),
    endpoints: builder => ({

        loginByUserName: builder.mutation<LoginByUserNameRes, LoginByUserNameParams>({

            query: (body) => ({
                url: '/member/auth/login/by/username',
                method: 'POST',
                body,
            }),
            transformResponse: (response: { data: LoginByUserNameRes }, undefind, _arg: LoginByUserNameParams) => response.data,
        }),


        loginByCode: builder.mutation<LoginByUserNameRes, LoginByCodeParams>({

            query: (body) => ({
                url: '/member/auth/sms-login',
                method: 'POST',
                body,
            }),
            transformResponse: (response: { data: LoginByUserNameRes }, undefind, _arg: LoginByCodeParams) => response.data,
        }),


        refreshToken: builder.mutation<LoginByUserNameRes, string>({

            query: (refreshToken: string) => ({
                url: `/member/auth/refresh-token?refreshToken=${refreshToken}`,
                method: 'POST',

            }),
            transformResponse: (response: { data: LoginByUserNameRes }, undefind, _arg: string) => response.data,
        }),
        // /app-api/member/auth/login/by/username

        getCode: builder.mutation<boolean, SmsCodeParams>({
            query: (body) => {
                return {
                    url: "/member/auth/send-sms-code",
                    method: "POST",
                    body,
                }
            }

        }),
        otherTest: builder.query({
            // builder.query 发送的是get 请求，  query()返回url
            // 通过LoginAPISlice.useOtherTestQuery() 返回
            //  {data,isLoading,isSuccess,isError,error}  异步操作

            query: (_params: any): string => "",

        })

    }),

})


// export const { useOtherTestQuery, useLoginByUserNameMutation, useGetCodeMutation, useLoginByCodeMutation, useRefreshTokenMutation } = LoginAPI
